import { awsExports } from "../configs/aws-exports";

export const useCommon = () => {
  // ユーザーIDの取得
  const userData = JSON.parse(
    localStorage.getItem(
      "CognitoIdentityServiceProvider." +
        awsExports.Auth.userPoolWebClientId +
        "." +
        localStorage.getItem(
          "CognitoIdentityServiceProvider." +
            awsExports.Auth.userPoolWebClientId +
            ".LastAuthUser",
        ) +
        ".userData",
    ),
  );
  // ユーザーIDの取得
  // userData.UserAttributesの配列の中から、NameがsubのValueを取得
  let userId = "";
  let lineUserId = "";
  userData.UserAttributes.forEach((attr) => {
    if (attr.Name === "sub") {
      userId = attr.Value;
    }
    if (attr.Name === "identities") {
      const identities = JSON.parse(attr.Value);
      lineUserId = identities[0].userId;
    }
  });

  return {
    userData,
    userId,
    lineUserId,
  };
};
