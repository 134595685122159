export const awsExports = {
  Auth: {
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}`,
    identityPoolId: `${process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}`,
      scope: ["profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN_URL}`,
      redirectSignOut: `${process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT_URL}`,
      clientId: `${process.env.REACT_APP_COGNITO_OAUTH_CLIENT_ID}`,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  Logging: {
    // ログの種類ごと
    logGroupName: `${process.env.REACT_APP_LOGGING_LOG_GROUP_NAME}`,
    // ログを出力するアプリケーションのインスタンスごと
    logStreamName: `${process.env.REACT_APP_LOGGING_LOG_STREAM_NAME}-subscription `,
    region: `${process.env.REACT_APP_LOGGING_REGION}`,
  },
};
