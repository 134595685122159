import "./App.css";
import { Router } from "./routers/Router";
import { Amplify, Auth } from "aws-amplify";
import {
  Authenticator,
  View,
  Button,
  Text,
  Image,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { awsExports } from "./configs/aws-exports";
import React from "react";
import ReactGA from "react-ga4";

Amplify.configure(awsExports);
ReactGA.initialize("G-YKXK8FGW3P");

function App() {
  const components = {
    SignIn: {
      Header() {
        return (
          <View padding="2rem" textAlign="center">
            <div className="mb-3">
              <Image
                alt="logo"
                src="./icon.png"
                objectFit="initial"
                objectPosition="50% 50%"
                height="50%"
                width="50%"
              />
            </div>
            <div className="mb-3">
              <Text>
                より良いクリニック環境と経営のための動画研修サービス『歯科LINE』にご興味を持っていただき、誠にありがとうございます。
              </Text>
              <br />
              <Text>下記ボタンよりお申し込みへとお進みください。</Text>
            </div>
            <div className="mt-4 mb-2">
              <Button
                variation="primary"
                colorTheme="success"
                isFullWidth={true}
                className="mb-2"
                onClick={() => Auth.federatedSignIn({ provider: "LINE" })}
              >
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-4 d-block">
                    『歯科LINE』お申し込みフォームへ
                  </span>
                </div>
              </Button>
              <Text>※LINEログイン後、お申し込みフォームに遷移します。</Text>
            </div>
          </View>
        );
      },
      Footer() {
        return <></>;
      },
    },
  };

  return (
    <>
      <Authenticator
        loginMechanisms={["email"]}
        hideSignUp={true}
        variation="modal"
        components={components}
      >
        <Router />
      </Authenticator>
    </>
  );
}

export default App;
// export default withAuthenticator(App);
