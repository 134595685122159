import React, { useEffect } from "react";
import { useProjectSubscription } from "../hooks/useProjectSubsctiption";
import { CustomSpinner } from "../components/CustomSpinner";
import { useCommon } from "../hooks/useCommon";
import { lineAccountUrl } from "../configs/common";
import { SignOut } from "../components/SignOut";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const SubscriptionSuccessPage = () => {
  const { userId } = useCommon();

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const {
    getProjectSubscription,
    projectSubscription,
    projectSubscriptionLoading,
  } = useProjectSubscription();

  useEffect(
    () => getProjectSubscription(userId),
    [getProjectSubscription, userId],
  );

  const getProductName = (productId) => {
    switch (productId) {
      case "yearly_5":
        return "1-5名 年額プラン";
      case "yearly_10":
        return "1-10名 年額プラン";
      case "yearly_20":
        return "1-20名 年額プラン";
      case "monthly_5":
        return "1-5名 月額プラン";
      case "monthly_10":
        return "1-10名 月額プラン";
      case "monthly_20":
        return "1-20名 月額プラン";
      default:
        return "プランがありません";
    }
  };

  return (
    <div className="min-vh-100">
      <div className="container main py-3">
        <div className="mt-3 mb-3">
          <h1 className="text-center">
            お申し込み<span onClick={() => SignOut()}>完了</span>
          </h1>
        </div>
        {projectSubscriptionLoading ? (
          <CustomSpinner />
        ) : (
          <>
            <div className="mb-3">
              <div className="d-flex justify-content-center">
                <div className="col-6">
                  <img
                    src="./icon.png"
                    alt="お申し込み完了"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <div>
                    <p>
                      医院名：{projectSubscription?.projectName}
                      <br />
                      プラン：{getProductName(projectSubscription?.productId)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <h2 className="fw-bold service-color-text mb-3">
                ■アカウント情報をお知らせします
              </h2>
              <p className="fw-bold">
                歯科LINE公式アカウント：
                <a href={lineAccountUrl} target="_blank" rel="noreferrer">
                  {lineAccountUrl}
                </a>
              </p>
              <p>
                ※医院長向け管理アカウントとは別の動画視聴用のLINEアカウントです
              </p>
              <p className="fw-bold">
                医院ID：{projectSubscription?.projectPasscode}
              </p>
              <p>※医院IDは視聴アカウントの初回登録時に必要になります</p>
            </div>
            <div className="mb-3">
              <h2 className="fw-bold service-color-text mb-3">■今後の流れ</h2>
              <p>
                上記のLINEアカウントを友だち登録いただき、動画視聴まで進行いただけますと幸いです。その後、上記のアカウント情報をスタッフに送付くださいませ。
              </p>
            </div>
            <div className="mb-3">
              <h2 className="fw-bold service-color-text mb-3">
                ■動画視聴までのステップ
              </h2>
              <p>
                歯科LINE公式アカウントの友達追加 →
                初回登録（氏名、医院IDを入力）→ 動画視聴
              </p>
            </div>
            <div className="mb-3">
              <h2 className="fw-bold service-color-text mb-3">
                ■スタッフへの送付用サンプルテキスト
              </h2>
              <div className="bg-secondary bg-gradient rounded p-3 text-white mb-3">
                <p>
                  当院では、より良いクリニック環境と経営のための動画研修サービス『歯科LINE』を導入しました。週に1度、研修動画（数分程度）が配信されます。ぜひご覧ください。
                </p>
                <p>以下のLINEの友達登録と初回登録をお願いします。</p>
                <p>歯科LINE公式アカウント：{lineAccountUrl}</p>
                <p>医院ID：{projectSubscription?.projectPasscode}</p>
              </div>
              <div>
                <button
                  className="btn service-color-btn text-white"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `当院では、より良いクリニック環境と経営のための動画研修サービス『歯科LINE』を導入しました。週に1度、研修動画（数分程度）が配信されます。ぜひご覧ください。\n以下のLINEの友達登録と初回登録をお願いします。\n\n歯科LINE公式アカウント：${lineAccountUrl}\n医院ID：${projectSubscription?.projectPasscode}`,
                    );
                    alert("送付用サンプルテキストをコピーしました");
                  }}
                >
                  送付用サンプルテキストをコピー
                </button>
              </div>
            </div>
            <div className="mb-3">
              <h2 className="fw-bold service-color-text mb-3">
                ■お申し込みのお礼
              </h2>
              <p>
                より良いクリニック環境と経営のための動画研修サービス『歯科LINE』にお申し込みいただき、誠にありがとうございます。
                LINEにも同様の情報をお送りしておりますのでご安心くださいませ。
                <br />
                <br />
                ご不明点がございましたら、歯科LINE（医院長向け管理アカウント）へお気軽にお問い合わせください。
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
