import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/ErrorFallback";
import {
  Logger as AmplifyLogger,
  AWSCloudWatchProvider,
  Amplify,
} from "aws-amplify";
import { SubscriptionRegisterPage } from "../pages/SubscriptionRegisterPage";
import { SubscriptionSuccessPage } from "../pages/SubscriptionSuccess";

export const Router = () => {
  const onError = (error, info) => {
    // errorログ
    console.log("Error boundary", error.message);
    console.log("Error boundary", info.componentStack);

    // cloudWatchにエラーを出力
    const awsLogger = new AmplifyLogger("log-prefix", "INFO");
    Amplify.register(awsLogger);
    awsLogger.addPluggable(new AWSCloudWatchProvider());
    awsLogger.error("Error boundary", error.message, info.componentStack);
  };

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
        <Routes>
          <Route>
            <Route index element={<SubscriptionRegisterPage />} />
            <Route
              path={"/subscription-success"}
              element={<SubscriptionSuccessPage />}
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
