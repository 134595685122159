export const ErrorFallback = (error) => {
  return (
    <div className="bg-light min-vh-100">
      <div className="container main">
        <div className="row gx-5">
          <div className="mt-5 text-center">
            <h2>エラーが発生しました。</h2>
            <div className="mt-3 mb-3">
              <a href="/">トップに戻る</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
