import { useCallback, useState } from "react";
import axiosInstance from "../configs/axios";
import { awsExports } from "../configs/aws-exports";

export const useProjectSubscription = () => {
  const [projectSubscriptionLoading, setProjectSubscriptionLoading] =
    useState(true);
  const [projectSubscription, setProjectSubscription] = useState({});

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getProjectSubscription = useCallback((userId) => {
    setProjectSubscriptionLoading(true);
    let url = "/project-subscription?user_id=" + userId;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setProjectSubscription(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          console.log("404エラー: データが見つかりませんでした。");
        } else {
          console.log("初期ロードに失敗: " + err);
        }
      })
      .finally(() => setProjectSubscriptionLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getProjectSubscription,
    projectSubscription,
    projectSubscriptionLoading,
  };
};
