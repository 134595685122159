import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { awsExports } from "../configs/aws-exports";
import axiosInstance from "../configs/axios";
import { useCommon } from "../hooks/useCommon";
import { FullScreenModal } from "../components/FullScreenModal";
import { UsagePolicyContent } from "../components/UsagePolicyContent";
import { PrivacyPolicyContent } from "../components/PrivacyPolicyContent";
import { SignOut } from "../components/SignOut";
import { useProjectSubscription } from "../hooks/useProjectSubsctiption";
import { CustomSpinner } from "../components/CustomSpinner";
import ReactGA from "react-ga4";

export const SubscriptionRegisterPage = () => {
  const [projectName, setProjectName] = useState("");
  const [projectNameValidation, setProjectNameValidation] = useState(false);
  const [productId, setProductId] = useState("");
  const [productIdValidation, setProductIdValidation] = useState(false);
  const [address, setAddress] = useState("");
  const [addressValidation, setAddressValidation] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameValidation, setUsernameValidation] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const { userId, lineUserId } = useCommon();

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const {
    getProjectSubscription,
    projectSubscription,
    projectSubscriptionLoading,
  } = useProjectSubscription();

  useEffect(
    () => getProjectSubscription(userId),
    [getProjectSubscription, userId],
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (projectSubscriptionLoading) return;
    if (Object.keys(projectSubscription).length >= 1) {
      navigate("/subscription-success");
    }
  }, [navigate, projectSubscription, projectSubscriptionLoading]);

  const onClickCreateSubscription = async () => {
    const accessToken = localStorage.getItem(
      "CognitoIdentityServiceProvider." +
        awsExports.Auth.userPoolWebClientId +
        "." +
        localStorage.getItem(
          "CognitoIdentityServiceProvider." +
            awsExports.Auth.userPoolWebClientId +
            ".LastAuthUser",
        ) +
        ".accessToken",
    );

    if (!projectNameValidation) {
      alert("医院名を入力してください");
      return;
    }

    if (!addressValidation) {
      alert("医院住所を入力してください");
      return;
    }

    if (!usernameValidation) {
      alert("氏名を入力してください");
      return;
    }

    if (!productIdValidation) {
      alert("プランを選択してください");
      return;
    }

    await axiosInstance
      .post(
        "/create-subscription",
        {
          userId: userId,
          lineUserId: lineUserId,
          productId: productId,
          projectName: projectName,
          address: address,
          username: username,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        },
      )
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data["checkout_url"];
      })
      .catch((err) => alert("Error: " + err));
  };

  const onChangeProjectName = (e) => {
    setProjectName(e.target.value);
    if (e.target.value.length < 100 && e.target.value.length > 0) {
      setProjectNameValidation(true);
    } else {
      setProjectNameValidation(false);
    }
  };

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
    if (e.target.value.length < 200 && e.target.value.length > 0) {
      setAddressValidation(true);
    } else {
      setAddressValidation(false);
    }
  };

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
    if (e.target.value.length < 100 && e.target.value.length > 0) {
      setUsernameValidation(true);
    } else {
      setUsernameValidation(false);
    }
  };

  const onChangeProductId = (e) => {
    setProductId(e.target.value);
    if (e.target.value === "") {
      setProductIdValidation(false);
    }
    if (e.target.value.length > 0) {
      setProductIdValidation(true);
    } else {
      setProductIdValidation(false);
    }
  };

  return (
    <div className="min-vh-100">
      <div className="container main py-3">
        <div className="mt-3 mb-3">
          <h1 className="text-center fw-bold">
            <span onClick={() => SignOut()}>『歯科LINE』</span>
            <br />
            お申し込みフォーム
          </h1>
        </div>
        {projectSubscriptionLoading ? (
          <CustomSpinner />
        ) : (
          <>
            <div className="mb-3">
              <p>お申し込みまでのステップ</p>
              <ol>
                <li>本フォームの入力</li>
                <li>プラン選択</li>
                <li>クレジットカード登録</li>
                <li>完了画面</li>
              </ol>
            </div>
            <div className="mb-5">
              <div className="mb-3">
                <label htmlFor="project_name" className="form-label fw-bold">
                  医院名
                </label>
                <input
                  type="text"
                  id="project_name"
                  className="form-control"
                  defaultValue={projectName}
                  placeholder={"(例)山田歯科医院"}
                  onChange={(e) => onChangeProjectName(e)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label fw-bold">
                  医院住所
                </label>
                <input
                  type="text"
                  id="address"
                  className="form-control"
                  defaultValue={address}
                  placeholder={"(例)東京都文京区本郷3-25-1"}
                  onChange={(e) => onChangeAddress(e)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="username" className="form-label fw-bold">
                  氏名
                </label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  defaultValue={username}
                  placeholder={"(例)山田　太郎"}
                  onChange={(e) => onChangeUsername(e)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="fw-bold service-color-text">プラン選択</h2>
              <div className="mb-4">
                <ol>
                  <li>
                    <p>
                      年額プラン
                      <span className="service-color-text">
                        {" "}
                        年払いなら16%オフ
                      </span>
                      <br />
                      4,583円/月 (55,000円/年)
                      <br />
                      9,166円/月 (110,000円/年)
                      <br />
                      13,750円/月 (165,000円/年)
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      月額プラン
                      <br />
                      5,500円/月 (66,000円/年)
                      <br />
                      11,000円/月 (132,000円/年)
                      <br />
                      15,000円/月 (180,000円/年)
                    </p>
                  </li>
                </ol>
              </div>
              <div className="mb-3 bg-gradient px-3 pt-3 pb-1 text-white rounded service-color">
                <p className="fw-bold">
                  ただいまリリース記念キャンペーン中！(9/30まで)
                </p>
                <p className="fw-bold">20名(20アカウント)まで同一料金でOK!</p>
                <p>
                  [通常料金]
                  <br />
                  5名まで 5,500円(月額) / 55,000円(年額)
                  <br />
                  10名まで 11,000円(月額) / 110,000円(年額)
                  <br />
                  20名まで 16,500円(月額) / 165,000円(年額)
                  <br />
                </p>
              </div>
              <div className="mb-3">
                <select
                  id="product_id"
                  className="form-select form-select-lg"
                  defaultValue={productId}
                  onChange={(e) => onChangeProductId(e)}
                >
                  <option value="">プランを選択してください</option>
                  <option value="yearly_5">
                    1.
                    年額プラン[1-5名]（年額を一括支払い）4,583円/月（55,000円/年）
                  </option>
                  <option value="yearly_10">
                    2.
                    年額プラン[6-10名]（年額を一括支払い）4,583円/月（55,000円/年）※キャンペーン適用済み
                  </option>
                  <option value="yearly_20">
                    3.
                    年額プラン[11-20名]（年額を一括支払い）4,583円/月（55,000円/年）※キャンペーン適用済み
                  </option>
                  <option value="monthly_5">
                    4.
                    月額プラン[1-5名]（月毎の支払い）5,500円/月（66,000円/年）
                  </option>
                  <option value="monthly_10">
                    5.
                    月額プラン[6-10名]（月毎の支払い）5,500円/月（66,000円/年）※キャンペーン適用済み
                  </option>
                  <option value="monthly_20">
                    6.
                    月額プラン[11-20名]（月毎の支払い）5,500円/月（66,000円/年）※キャンペーン適用済み
                  </option>
                </select>
              </div>
            </div>
            <div className="mb-5">
              <h2 className="fw-bold service-color-text">よくあるご質問</h2>
              <ul>
                <li>
                  支払い日について
                  <br />
                  購入頂いた日を基準に、毎月（毎年）同じ日にクレジットカードにて決済いたします。（31日など毎月存在しない日付の場合は月末に変更）引き落とし日はカード会社に準じます。
                </li>
                <li>
                  利用更新の停止について
                  <br />
                  決済日の前日24時までに利用更新の停止申請をお願いいたします。翌月から利用更新を停止できます。（例：1/20が決済日の場合は、1/18の24時までに利用更新の停止申請が必要）
                </li>
                <li>
                  月額払いから年払いに変更したい場合
                  <br />
                  日割りにて計算し、差額分のみで変更が可能です。ご不明点はございましたら、公式LINEアカウント『歯科LINE（院長向け管理アカウント）』へチャットにてお問い合わせください
                </li>
                <li>
                  領収書を発行したい
                  <br />
                  院長向け管理アカウントでログインいただき簡単に発行できます（お支払い→領収書→ダウンロードボタンを押下）
                </li>
              </ul>
              <p>
                その他ご不明点がございましたら、歯科LINE（医院長向け管理アカウント）へお気軽にお問い合わせください。
              </p>
            </div>
            <div className="mb-3">
              <h2 className="fw-bold service-color-text mb-2">
                利用規約・プライバシーポリシー
              </h2>
              <p>
                利用規約は
                <FullScreenModal title="利用規約">
                  <UsagePolicyContent />
                </FullScreenModal>{" "}
              </p>
              <p>
                プライバシーポリシーは
                <FullScreenModal title="プライバシーポリシー">
                  <PrivacyPolicyContent />
                </FullScreenModal>{" "}
              </p>
              <p>
                上記利用規約・プライバシーポリシーに同意の上、クレジットカード登録にお進みください。
              </p>
              <div className="form-check">
                <input
                  className="form-check-input service-color-checkbox"
                  type="checkbox"
                  checked={agreeCheck}
                  onChange={() => setAgreeCheck(!agreeCheck)}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  利用規約・プライバシーポリシーに同意する
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-grid gap-2">
                <button
                  disabled={!agreeCheck}
                  className="btn btn-primary btn-lg service-color-btn"
                  onClick={() => onClickCreateSubscription()}
                >
                  クレジットカードの登録に進む
                </button>
              </div>
            </div>
            <div className="mb-3">
              <p>
                ※クーポンコードは次のクレジットカード登録画面でご入力いただけます
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
